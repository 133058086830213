import React from 'react';

/**
 * Images/SVG
 */
import { CanonLogo } from '../components/canon-logo/canon-logo';
import JllLogo from '../images/jll_logo.png';
import UsiLogo from '../images/usi-logo.png';
import CanonPro from '../images/pro.png';
import CanonCPS from '../images/cps.png';
import Jll from '../images/jll1.png';
import Usimoney from '../images/usi.png';
import Background from '../images/Radium_sketches.png';

/**
 * Components
 */
import SEO from '../components/seo/seo';
import Layout from '../components/layout/layout';
import DefaultBanner from '../components/default-banner/default-banner';
import ClientsContainer from '../components/clients-container/clients-container';

export default () => {

  const innerBannerData = {
    backgroundImg: { backgroundImage: `url(${Background}` },
    mainTitle: 'Our Clients',
    mainText: 
      'Here are some of the amazing startups and brands we have worked with.'
  };

  /**
   * Client data block
   */
  const clientsData = {

    home: false,

    hidden: [
      {
        mainImg: <img alt="jll" src={Jll}/>,
        logo: <img alt="jll logo" src={JllLogo}/>,
        link: 'jll',
        subTitle: 
          'JJL – Creating a unique service standout in the real estate market',
        text: 
          'We are the company responsible for the property management of The Crown Estate’s central London holding, including its Regent Street portfolio.',
        companyLink: 'https://www.myregentstreet.com/'
      }
    ],
    view: [
      {
        mainImg: <img alt="pro" src={CanonPro}/>,
        logo: <CanonLogo />,
        link: 'pro',
        subTitle: 'Canon PRO – Enhancing product visibility with Strategic Marketing',
        text: 
          'Canon Europe is a subsidiary of Canon Inc. a world-leading innovator and provider of imaging solutions such inkjet printers, digital cameras, digital SLR cameras, & professional printers for business and home users.',
        companyLink: 'https://www.canon-europe.com/pro'
      },
      {
        mainImg:  <img alt="usi" src={Usimoney}/>,
        logo: <img alt="usi logo" src={UsiLogo}/>,
        link: 'usimoney',
        subTitle: 'USI MONEY – Delivering digital transformation across all platforms',
        text: 
          'USI Money is a unique foreign exchange provider offering bespoke solutions to both individual and corporate clients. USI Money is the ideal partner to trade your FX with. They hold the one of the biggest Foreign Exchange markets in the World.',
        companyLink: 'https://www.usimoney.com/'
      },
      {
        mainImg:  <img alt="cps" src={CanonCPS}/>,
        logo: <CanonLogo />,
        link: 'cps',
        subTitle: 
          'CANON CPS – Upgrading Customers’ Portal to deliver priority support & operational efficiency',
        text: 
          'Canon professional services provide canon customers the opportunity to tell their exciting stories better and become full time imaging professionals with priority support. These include exclusive phone and email support, equipment loans and repairs, on-site support, discounts for events and special offers.',
        companyLink: 
          'https://www.canon-europe.com/pro/canon-professional-services/'
      }
    ]
  };

  return (
    <Layout>
      <SEO 
        title="Clients" 
        keywords={[ `digital`, `agency`, `technology`, `Radium`, `Clients` ]} 
      />
      <DefaultBanner {...innerBannerData}/>
      <ClientsContainer {...clientsData}/>
    </Layout>
  )
};
