import React from 'react';

import ClientTemplate from '../clients-template/clients-template';
import Accordion from '../accordion/accordion';
import Styles from './clients-container.module.scss';

export default ({ home, view, hidden }) => (
    
  <section className={Styles.clients}>
    <h2 
      className={Styles.sectionTitle}
      style={home ? {} : { display: 'none' }}
    >
      Our Clients
    </h2>
    <div className="container">
      <p 
        className={Styles.text}
        style={home ? {} : { display: 'none' }}
      >
        Here are some of the amazing startups and brands we have worked with.
      </p>
      <div className={Styles.clientsContainer}>
        {view.map( ( value, index ) => 
          <ClientTemplate key={index} {...value}/>
        )}
      </div>
      <Accordion {...hidden}/>
    </div>
  </section>
);
