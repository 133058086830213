import React from 'react';
import { Link } from 'gatsby';
import { FaArrowRight } from 'react-icons/fa';

import Styles from './clients-template.module.scss';

/**
 * Why inline styling
 * Its unfortunate but this atribute is from the old flexbox2009
 * Not having replacement and currently falling out of the browserlist means
 * sass will ignore it, try it yourself
 */
export default ({ mainImg, link, logo, subTitle, text }) => (
  
  <div className={Styles.clientContent}>
    <Link
      to={`/clients/${link}`}
      className={Styles.ImgOuterContainer}
    >
      <div className={Styles.ImgInnerContainer}>
          {mainImg}
      </div>
    </Link>
    <div className={Styles.textContainer}>
      <div className={Styles.logoImg}>
        {logo}
      </div>
      <h4 className={Styles.subTitle}>{subTitle}</h4>
      <p className={Styles.text}>
        {text}
      </p>
      <Link 
        className="button__content button--svg-animation"
        to={`/clients/${link}`}
      >
        See More
        <FaArrowRight />
      </Link>
    </div>
  </div>
);
